<template>
<div class="row mb-5">
        <div class="col-md-12">

                    <span class="h4 titulosg mt-0"><strong>Asignación de Permisos <span class="text-primary">Individual</span></strong></span> <br>
                    <span class="text-secondary">Consulte y asigne los permisos de cada usuario registrado en el sistema</span>

                <div class="mt-4 mb-4">
                    <form v-on:submit.prevent="listapersonas">
                        <label for="criteriobusqueda" class="form-label">Nombre o identificación de la persona</label>
                            <input  autocomplete="off" type="text" class="form-control  form-control-lg titulosg text-secondary"   v-model="criteriobusqueda" id="criteriobusqueda"  required aria-describedby="button">
                            <button class="btn btn-primary mt-2" type="submit" id="button"><i class="fas fa-search"></i> Buscar Usuario</button>
                    </form>
                </div>
   
                        
                <div class="mb-5" v-if="paso == 2">
                        
                    <strong>Resultadoa Encontrados <span class="badge bg-primary">{{ personas.length }}</span> <i class="fas fa-angle-right p-3"></i> Para: <span class="text-primary">{{criteriobusqueda}}</span>  </strong> 


                    <div class="list-group mt-2">
                        <a href="#permisosasignados" v-for="persona in personas" @click="permisosxpersona(persona.idpersonas, persona.nombres+' '+persona.apellidos)" class="list-group-item list-group-item-action text-capitalize">
                            {{persona.nombres}} {{persona.apellidos}}
                        </a>
                    </div>
                </div>

                <div  id="permisosasignados" v-if="paso == 3">

                    <form  v-on:submit.prevent="agregaroquitarpermiso">

                        <div class="mb-4">
                            <span>Asignación de Permisos Para:</span> <br>                
                            <span class="h5 titulosg"> <strong>{{infopersona.nombre}}</strong></span>
                            <input type="hidden" v-model="infopersona.id">
                        </div>
                    

                        <div class="row">
                            <div class="col-md-12 mb-3" v-for="permiso in permisos.filter(permisos => permisos.permisos_idpermisos === null)" :key="permiso.idpermisos" >
                                <div class="card">
                                        <div class="p-3">
                                            <input type="checkbox" class="form-check-input me-3" name="permisosguardar[]" :id="permiso.idpermisos" :value="permiso.idpermisos" v-model="permisosactuales">

                                                <a :href="'#p'+permiso.idpermisos" role="button"  data-bs-toggle="collapse" aria-expanded="false" aria-controls="collapseExample">                             
                                                    <span v-html="permiso.icono" class="me-2"></span>  <strong class="titulosg">{{ permiso.nombrepermiso }}</strong>   <i class="fas fa-sort-down me-5"></i> 
                                                </a>
                                                <br>
                                                <span class="text-black-50"> {{permiso.descripcion}} </span>
                                        </div>
                
                                        <div class="collapse" :id="'p'+permiso.idpermisos" >
                                            <div class="list-group list-group-flush">
                                                <label class="list-group-item list-group-item-action" v-for="(sub, index) in subpermisos.filter(permisos => permisos.permisos_idpermisos === permiso.idpermisos)" :key="sub.idpermisos">                                              
                                                <input type="checkbox" class="form-check-input me-3" name="permisosguardar[]" :id="sub.idpermisos" :value="sub.idpermisos" v-model="permisosactuales">
                                                <span v-html="sub.icono" class=" text-dark me-2"></span> {{ sub.nombrepermiso }}  
                                                            <span class="text-black-50 ms-3">{{ sub.descripcion }}</span>   
                                                </label>                                             
                                            </div>
                                        </div>
                
                                </div>
                            </div>
                        </div>

                        <div class="alert alert-warning">
                            Permisos Asignar <span class="badge bg-primary">{{permisosactuales.length}}</span> de <span class="badge bg-secondary"> {{permisos.length}} </span>
                        </div>

                        <button type="submit" class="btn btn-primary"  :disabled="boton"> 
                            <div class="spinner-border spinner-border-sm" role="status" v-if="boton">
                                <span class="visually-hidden">Loading...</span>
                              </div>
        
                            <i class="fas fa-save"></i> Guardar Cambios
                        </button>      

                 </form>

 
                </div>
    </div>

    
</div>

</template>

 <script>

import {ref, reactive, onMounted} from 'vue'
import axios from 'axios'

export default {
        setup(props) {
            
            const criteriobusqueda = ref(null) 
            const personas = ref([])
            const infopersona = reactive({ nombre: 'inicio', id: '44555' })
            const permisos = ref([])
            const subpermisos = ref([]) 
            const permisosactuales = ref([]) 
            const paso = ref(1) 

            const boton = ref(false) 

            const listapersonas = () => {
                    axios.post('/api/busquedapersona', { data: criteriobusqueda.value  }).then(response => { 
                        personas.value = response.data;    
                    });
                    
                    paso.value = 2;
            }
                
            const permisosxpersona = (id, nombre) =>  {

                    criteriobusqueda.value = null
                    infopersona.nombre = nombre
                    infopersona.id = id


                    //consulta de los permisos asignados 
                    axios.get('/api/permisosasignados/'+id).then(response => { 
                                
                                for (var i = 0; i < response.data.length; i++) {
                                        permisosactuales.value[i]= response.data[i].idpermisos
                                    }
                    })

                    //Para mostrar luego de seleccionar la persona
                    paso.value = 3;

            }

            const agregaroquitarpermiso = () => {

                boton.value = true
                    axios.post('/api/agregaroquitarpermisoindividual', { permisos: permisosactuales.value, idpersona: infopersona.id })
                    .then(response => { 
                            
                            if(response.status==200)
                            {
                                swal("Bien!", "Información Actualizada Correctamente!", "success");
                            }else{
                                swal(":(", "Intentemos Nuevamente", "error");
                            }

                        boton.value = false

                     });
            }

            const listarpermisos = () => {
                axios.get('/api/permisos').then(response => { permisos.value = response.data;  subpermisos.value = response.data;   });

            }

            onMounted(() => {
                listarpermisos()
            })
            
            return {
                boton,
                criteriobusqueda,
                personas,
                infopersona,
                permisos,
                subpermisos,
                permisosactuales,
                paso,

                //Funciones
                listapersonas,
                permisosxpersona,
                agregaroquitarpermiso
            }
        } 
}
 </script>
 